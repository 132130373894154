import { PbIcon } from "@/components/pbIcon/PbIcon";
import { SvgIds } from "@/components/pbIcon/svg";

export interface CmsPageWarningProps {
  type: "hint" | "warning" | "success" | "error";
  children: React.ReactNode;
  hideLabel?: boolean;
  /* Optional Icon */
  icon?: SvgIds;
  /* optional CSS width  */
  width?: string;
  /* optional CSS width  */
  maxWidth?: string;
  /* optional style prop */
  style?: React.CSSProperties;
}

/**
 * CmsPageWarning
 * Displays HTML-Text as Warning.
 *
 * @param {React.ReactNode} children
 * @returns
 */
const CmsPageWarning = (props: CmsPageWarningProps) => {
  const getColors = (): { bgColor: string; borderColor: string } => {
    switch (props.type) {
      case "warning":
        return { bgColor: "#fffbeb80", borderColor: "#ad680063" };
      case "success":
        return { bgColor: "#e9fbf0", borderColor: "#c6f6d9" };
      case "error":
        return { bgColor: "#fff0f1", borderColor: "#ffdcdf" };
      case "hint":
      default:
        return { bgColor: "#f6f7f8", borderColor: "#e8eaee" };
    }
  };

  const colors = getColors();
  const cssWidth = props.width ?? "unset";
  const cssMaxWidth = props.maxWidth ?? "none";

  return (
    <>
      <div className="cms-page-warning d-flex" style={props.style}>
        <div>
          {props.icon && (
            <PbIcon
              name={props.icon}
              width={20}
              height={20}
              style={{ marginRight: "8px" }}
            />
          )}
        </div>
        <div>{props.children}</div>
      </div>
      <style jsx>{`
        .cms-page-warning {
          padding: 1.5rem;
          border: 1px solid ${colors.borderColor};
          border-radius: 8px;

          width: ${cssWidth};
          max-width: ${cssMaxWidth};

          line-height: 20px;

          margin-left: auto;
          margin-right: auto;

          margin-top: 1.5rem;
          margin-bottom: 1.5rem;

          color: black;
          background-color: ${colors.bgColor};

          /* Hide if no children exist. */
          &:empty {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default CmsPageWarning;
