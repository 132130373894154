import CmsConfigModal from "@/components/cms/cmsConfigModal/cmsConfigModal";
// HTML Content element can not be imported dynamically
import HtmlContentElement from "@/components/contentelements/htmlContentElement/htmlContentElement";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScrollTo from "@/hooks/useScrollTo";
import useStrBgImageOnPage from "@/hooks/useStrBgImageOnPage";
import {
  ceSettingById,
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import { openFormsEditInNewTab } from "@/services/cmsFormService/cmsFormService";
import { moveInArrayAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  showMediaSelectionModal,
  showNestedContentModal,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  ContentElementAccordionStoreSetting,
  ContentElementButtonStoreSetting,
  ContentElementCardsStoreSetting,
  ContentElementCarouselStoreSetting,
  ContentElementDateStoreSetting,
  ContentElementFormStoreSetting,
  ContentElementGalleryStoreSetting,
  ContentElementHeadlineStoreSetting,
  ContentElementHtmlStoreSetting,
  ContentElementImageStoreSetting,
  ContentElementImageTickerStoreSetting,
  ContentElementImageWithMarkersStoreSetting,
  ContentElementMultimediaStoreSetting,
  ContentElementOverviewPageStoreSetting,
  ContentElementQuickLinksStoreSetting,
  ContentElementRichTextStoreSetting,
  ContentElementSearchBarStoreSetting,
  ContentElementSeparatorStoreSetting,
  ContentElementSliderStoreSetting,
  ContentElementSpacerStoreSetting,
  ContentElementTextWithImageStoreSetting,
} from "@/types/ceSettings/ceSettings";
import {
  CEButton,
  CECarousel,
  CEDate,
  CEForm,
  CEGallery,
  CEHeadline,
  CEImage,
  CEMultimedia,
  CEOverviewPage,
  CEQuickLinks,
  CERichText,
  CESearchBar,
  CESlider,
  CESpacer,
  CETextWithImage,
} from "@/types/content-elements";
import { getIdOrNewId } from "@/utils/util";
import { closestCorners, DndContext } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import CmsAddContentButton from "../cms/cmsAddContent/cmsAddContentButton";
import CmsAddContentModal from "../cms/cmsAddContent/cmsAddContentModal";
import CmsContentFrame from "../cms/cmsContentFrame/cmsContentFrame";
import CmsDraggableItemDndKit from "../cms/cmsDraggableItemDndKit/cmsDraggableItemDndKit";
import CmsNestedContentModal from "../cms/cmsNestedContentModal/cmsNestedContentModal";
import PbButton from "../input/pbButton/pbButton";
import { PbIcon } from "../pbIcon/PbIcon";
import { globalConfig } from "@/services/globalConfig/globalConfigService";

const ImageWithMarkersContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/imageWithMarkersContentElement/imageWithMarkersContentElement"
    )
);

const QuickLinksContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/quickLinksContentElement/quickLinksContentElement"
    )
);

const SearchBarContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/searchBarContentElement/searchBarContentElement"
    )
);

const CardsContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/cardsContentElement/cardsContentElement"
    )
);

const SearchResultsContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/searchResultsContentElement/searchResultsContentElement"
    )
);

const AccordionContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/accordionContentElement/accordionContentElement"
    )
);

const GalleryDefaultContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/galleryContentElement/default/galleryDefaultContentElement"
    )
);

const GalleryMasonryContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/galleryContentElement/masonry/galleryMasonryContentElement"
    )
);

const GalleryGridContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/galleryContentElement/grid/galleryGridContentElement"
    )
);

const TextWithImageContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/textWithImageContentElement/textWithImageContentElement"
    )
);

const ImageTickerContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/imageTickerContentElement/imageTickerContentElement"
    )
);

const MultimediaContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/multimediaContentElement/multimediaContentElement"
    )
);

const HeadlineContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/headlineContentElement/headlineContentElement"
    )
);

const SpacerContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/spacerContentElement/spacerContentElement"
    )
);

const ButtonContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/buttonContentElement/buttonContentElement"
    )
);

const ImageContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/imageContentElement/imageContentElement"
    )
);

const FormContentElement = dynamic(
  () =>
    import("@/components/contentelements/formContentElement/formContentElement")
);

const RichTextContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/richTextContentElement/richTextContentElement"
    )
);

const SeparatorContentElement = dynamic(
  () => import("@/components/contentelements/separator/separatorContentElement")
);

const CarouselContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/carouselContentElement/carouselContentElement"
    )
);
const DateContentElement = dynamic(
  () =>
    import("@/components/contentelements/dateContentElement/dateContentElement")
);
const OverviewPageContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/overviewPageContentElement/overviewPageContentElement"
    )
);

const SliderContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/sliderContentElement/sliderContentElement"
    )
);

/**
 * Props for the DraftPageRenderer component.
 *
 * @typedef {Object} DraftPageRendererProps
 * @property {boolean} isMobile - Flag to indicate whether the view is on
 * a mobile device.
 */
export interface DraftPageRendererProps {
  isMobile: boolean;
}

/**
 * DraftPageRenderer component that renders the draft page content for
 * content managers.
 *
 * This component is responsible for rendering draft versions of pages for
 * content managers. It includes various controls such as `configFrames`,
 * `configModal`, and `addContentModal` for editing and managing draft
 * content. This ensures a clear separation between the public-facing view
 * and the content management view, facilitating efficient content creation
 * and management.
 *
 * @param {DraftPageRendererProps} props - The properties passed to the
 * component.
 * @returns {JSX.Element} The rendered draft page content.
 */
const DraftPageRenderer = (props: DraftPageRendererProps) => {
  const dispatch = useAppDispatch();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const draftPage = useAppSelector((state) => state.cmsEdit.data?.draftPage);

  const tCms = useCmsTranslation();
  const router = useRouter();

  const { backgroundImageClassName } = useStrBgImageOnPage(
    draftPage.strBgImage
  );

  useScrollTo();

  const showConfigModalButton = (attributes: any) => {
    return Object.entries(attributes).some((attribute) =>
      attribute[0].startsWith("cfg")
    );
  };

  const renderContentElement = (
    contentElement: any,
    contentSize: number,
    index: number,
    isLastElement: boolean,
    isFirstElement: boolean
  ) => {
    if (contentElement !== null) {
      const selectedContentElementSettingId = valueFromStoreSetting(
        contentElement[CFG_CONTENT_ELEMENT_SETTING_NAME]
      );
      switch (contentElement.__component) {
        case "pb.chdln":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <HeadlineContentElement
                content={contentElement as CEHeadline}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementHeadlineStoreSetting
                }
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.cbttn":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <ButtonContentElement
                content={contentElement as CEButton}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementButtonStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              ></ButtonContentElement>
            </CmsContentFrame>
          );
        case "pb.cfrm":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
              additionalButtons={[
                <PbButton
                  key="btn-change"
                  className="cms-config-frame-btn"
                  onClick={async () => {
                    if (contentElement.cfgSelectFormId !== 0) {
                      await openFormsEditInNewTab(
                        contentElement.cfgSelectFormId,
                        router.locale
                      );
                    }
                  }}
                  startIcon={
                    <PbIcon
                      name="pen-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />,
              ]}
            >
              <FormContentElement
                content={contentElement as CEForm}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementFormStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.cmg":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <ImageContentElement
                content={contentElement as CEImage}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementImageStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              ></ImageContentElement>
            </CmsContentFrame>
          );
        case "pb.cgllry":
          const galleryLayouts: any = {
            default: GalleryDefaultContentElement,
            masonry: GalleryMasonryContentElement,
            grid: GalleryGridContentElement,
          };
          let GalleryComponent = null;
          switch (contentElement.cfgStrLayout?.values[0]) {
            case "masonry":
              GalleryComponent = galleryLayouts.masonry;
              break;
            case "grid":
              GalleryComponent = galleryLayouts.grid;
              break;
            default:
              GalleryComponent = galleryLayouts.default;
              break;
          }
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
              additionalButtons={[
                <PbButton
                  key={"btn-change" + index}
                  className="cms-config-frame-btn"
                  title={tCms("process")}
                  onClick={() => dispatch(showNestedContentModal(index))}
                  startIcon={
                    <PbIcon
                      name="pen-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />,
              ]}
            >
              <GalleryComponent
                content={contentElement as CEGallery}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementGalleryStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.ctwi":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <TextWithImageContentElement
                content={contentElement as CETextWithImage}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementTextWithImageStoreSetting
                }
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.cmltmd":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
              additionalButtons={[
                <PbButton
                  key={"btn-change" + index}
                  className="cms-config-frame-btn"
                  title={tCms("process")}
                  onClick={() =>
                    dispatch(showMediaSelectionModal({ position: index }))
                  }
                  startIcon={
                    <PbIcon
                      name="pen-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />,
              ]}
            >
              <MultimediaContentElement
                content={contentElement as CEMultimedia}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementMultimediaStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.crchtxt":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <RichTextContentElement
                content={contentElement as CERichText}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementRichTextStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.csprtr":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <SeparatorContentElement
                content={contentElement}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementSeparatorStoreSetting
                }
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.cmgtckr":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
              additionalButtons={[
                <PbButton
                  key={"btn-change" + index}
                  className="cms-config-frame-btn"
                  title={tCms("process")}
                  onClick={() => dispatch(showNestedContentModal(index))}
                  startIcon={
                    <PbIcon
                      name="pen-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />,
              ]}
            >
              <ImageTickerContentElement
                content={contentElement}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementImageTickerStoreSetting
                }
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.cspcr":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <SpacerContentElement
                content={contentElement as CESpacer}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementSpacerStoreSetting
                }
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.ccrds":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <CardsContentElement
                content={contentElement}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementCardsStoreSetting
                }
                position={index}
                isLastElement={index == contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.csrchrslts":
          // Note: SearchResults has no CmsContentFrame.
          return (
            <SearchResultsContentElement
              content={contentElement}
              position={index}
              isLastElement={index == contentSize - 1}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.ccrsl":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
              additionalButtons={[
                <PbButton
                  key={"btn-change" + index}
                  className="cms-config-frame-btn"
                  title={tCms("process")}
                  onClick={() => dispatch(showNestedContentModal(index))}
                  startIcon={
                    <PbIcon
                      name="pen-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />,
              ]}
            >
              <CarouselContentElement
                content={contentElement as CECarousel}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementCarouselStoreSetting
                }
                isLastElement={isLastElement}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              />
            </CmsContentFrame>
          );
        case "pb.csrchbr":
          return globalConfig?.search?.searchEnabled ? (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <SearchBarContentElement
                content={contentElement as CESearchBar}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementSearchBarStoreSetting
                }
                isFirstElement={isFirstElement}
                isLastElement={isLastElement}
                isMobile={props.isMobile}
              />
            </CmsContentFrame>
          ) : null;
        case "pb.cqcklnks":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
              additionalButtons={[
                <PbButton
                  key={"btn-change" + index}
                  className="cms-config-frame-btn"
                  title={tCms("process")}
                  onClick={() => dispatch(showNestedContentModal(index))}
                  startIcon={
                    <PbIcon
                      name="pen-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />,
              ]}
            >
              <QuickLinksContentElement
                content={contentElement as CEQuickLinks}
                position={index}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementQuickLinksStoreSetting
                }
                isFirstElement={isFirstElement}
                isLastElement={isLastElement}
                isMobile={props.isMobile}
              ></QuickLinksContentElement>
            </CmsContentFrame>
          );
        case "pb.cccrdn":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <AccordionContentElement
                content={contentElement}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementAccordionStoreSetting
                }
                position={index}
                isLastElement={index == contentSize - 1}
                isFirstElement={isFirstElement}
                isMobile={props.isMobile}
              />
            </CmsContentFrame>
          );
        case "pb.chtml":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <HtmlContentElement
                content={contentElement}
                position={index}
                isLastElement={index == contentSize - 1}
                isFirstElement={isFirstElement}
                isMobile={props.isMobile}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementHtmlStoreSetting
                }
              />
            </CmsContentFrame>
          );
        case "pb.cmgwthmrkrs":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <ImageWithMarkersContentElement
                content={contentElement}
                position={index}
                isLastElement={index == contentSize - 1}
                isFirstElement={isFirstElement}
                isMobile={props.isMobile}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementImageWithMarkersStoreSetting
                }
              />
            </CmsContentFrame>
          );
        case "pb.cdt":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <DateContentElement
                content={contentElement as CEDate}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementDateStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
                publishDate={draftPage.publishDate} // publish date - page type
                publishedAt={draftPage.publishedAt} // publishAt - publish date from pbPage
              ></DateContentElement>
            </CmsContentFrame>
          );
        case "pb.csldr":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <SliderContentElement
                content={contentElement as CESlider}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementSliderStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              ></SliderContentElement>
            </CmsContentFrame>
          );
        case "pb.cvrvwpg":
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <OverviewPageContentElement
                content={contentElement as CEOverviewPage}
                ceSettings={
                  ceSettingById(selectedContentElementSettingId)
                    ?.setting as ContentElementOverviewPageStoreSetting
                }
                position={index}
                isLastElement={index === contentSize - 1}
                isMobile={props.isMobile}
                isFirstElement={isFirstElement}
              ></OverviewPageContentElement>
            </CmsContentFrame>
          );
        default:
          return (
            <CmsContentFrame
              titleIsDragHandler={true}
              customClassName="contentelement-frame"
              title={tCms("contentelement-" + contentElement.__component)}
              position={index}
              configModalButton={showConfigModalButton(
                contentElement.attributes
              )}
            >
              <pre style={{ height: "250px", overflow: "auto" }}>
                {JSON.stringify(contentElement, null, 2)}
              </pre>
            </CmsContentFrame>
          );
      }
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id && over.id && active.id !== over.id) {
      const activeIndex = draftPage.content.findIndex(
        (cE: any) =>
          `${cE.__component}-${cE.id}` === active.id ||
          `${cE.__component}-${cE.__new_id}` === active.id
      );
      const overIndex = draftPage.content.findIndex(
        (cE: any) =>
          `${cE.__component}-${cE.id}` === over.id ||
          `${cE.__component}-${cE.__new_id}` === over.id
      );
      // Remove after testing:
      global.log.info(`${activeIndex} -> ${overIndex}`);
      performMove(activeIndex, overIndex);
    }
  };

  const performMove = (fromIndex: number, toIndex: number) => {
    if (fromIndex === toIndex) {
      return;
    }
    dispatch(
      moveInArrayAction({
        attributePath: `draftPage.content[${fromIndex}]`,
        directionOrIndex: toIndex,
      })
    );
  };

  return (
    <DndContext
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <div
        // Note: This is (also) the restricting div for draggable elements (restrictToParentElement)
        id="draft-page"
        className={clsx(
          `draft-page ${!editView && "preview"}`,
          backgroundImageClassName
        )}
      >
        <SortableContext
          items={draftPage.content.map(
            (cE: any, i: number) => `${cE.__component}-${getIdOrNewId(cE, i)}`
          )}
          strategy={verticalListSortingStrategy}
        >
          {draftPage.content.map((draftPageElement: any, i: number) => {
            return (
              <CmsDraggableItemDndKit
                key={`DraftPage-${draftPageElement.__component}-${getIdOrNewId(
                  draftPageElement,
                  i
                )}`}
                id={`${draftPageElement.__component}-${getIdOrNewId(
                  draftPageElement,
                  i
                )}`}
                withExternalDragHandler={true}
              >
                {renderContentElement(
                  draftPageElement,
                  draftPage.content.length,
                  i,
                  i === draftPage.content.length - 1,
                  i === 0
                )}
              </CmsDraggableItemDndKit>
            );
          })}
        </SortableContext>
        <CmsAddContentButton />
        <CmsAddContentModal />
        <CmsConfigModal />
        <CmsNestedContentModal />
      </div>
    </DndContext>
  );
};

export default DraftPageRenderer;
