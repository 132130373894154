export const CmsContentFrameStyle = () => {
  return (
    <style jsx global>{`
      .draft-page .draggable-item:first-of-type .frame-bar {
        top: 15px;
      }

      .content-frame {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding: 0;

        &.content-frame-border::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border: 2px solid transparent;
          transition: border-color 0.3s;
          pointer-events: none;
        }
        &.content-frame-border:hover::after,
        &.content-frame-border:focus-within::after {
          border-color: var(--pb-cms-color);
          border-radius: 5px;
        }

        &:hover,
        &:focus-within {
          .frame-bar {
            pointer-events: none;
            .right-controls {
              display: flex;
              transition: display 0.3s;
            }
            .left-controls {
              display: flex;
              transition: display 0.3s;
            }
            .title {
              display: flex;
              transition: display 0.3s;
            }
          }
        }

        .frame-bar {
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: fit-content;
          .cms-config-frame-btn {
            background-color: var(--pb-cms-color);
            pointer-events: all;
            margin: 1px 1px 1px 0;
            &.no-text {
              min-width: 33px;
              max-height: 37px;
              border-radius: 0px;
            }
            &:first-of-type {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            &:last-of-type {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }

        .frame-bar .left-controls {
          display: none;
          z-index: 990;
          top: 0;
          left: 0;
          position: absolute;
          transform: translate(10px, -50%);
        }
        .frame-bar .right-controls {
          display: none;
          z-index: 990;
          top: 0;
          left: 100%;
          position: absolute;
          transform: translate(calc(-100% - 10px), -50%);
        }

        .frame-bar .title {
          display: none;
          z-index: 990;
          top: 0;
          left: 100%;
          position: absolute;
          transform: translate(-50%, -50%);
          text-align: center;
          color: #fff;
          top: 0;
          left: 50%;
          position: absolute;
          font-size: 16px;
          padding: 0.5rem 5rem;
          border-radius: 5px;
          background-color: var(--pb-cms-color);
          pointer-events: auto;

          .cms-config-frame-btn {
            height: 21px;
            padding: 0;
            min-width: 16px;
            margin-left: 8px;
            margin-right: 0px;
          }

          .cms-config-frame-drag-btn {
            position: absolute;
            left: 0.15rem;
            top: 0;
            height: 100%;
            width: 4rem;
          }
        }
      }

      .is-dragging {
        z-index: 99999;
        position: relative;

        .content-frame {
          &.content-frame-border:hover::after,
          &.content-frame-border:focus-within::after {
            // border-style: dotted;
            border-style: normal;
            border-width: 3px;
            border-color: var(--pb-cms-color);
            border-radius: 5px;
          }
          box-shadow: ${`-1px 0 15px 0 rgba(34, 33, 81, 0.01),
            0px 15px 15px 0 rgba(34, 33, 81, 0.25)`};

          // Optional:
          // transform: scale(0.75, 0.75);
          // transform-origin: top center;

          // Note: Without a background-color other UI-Elements are visible,
          // this does not look good while dragging.
          // Option 1: Apply a background-color to .content-frame, this would fix it for
          // all content-elements without configured background-image or background-color.
          // However: The content & UI-Elements of the element itself also look bad.
          // background: repeating-linear-gradient(
          //   -45deg,
          //   var(--pb-cms-color) 0,
          //   var(--pb-cms-color) 20px,
          //   var(--pb-white) 20px,
          //   var(--pb-white) 40px
          // );
          // Option 2: Apply a background-color to .content-element, overwrite any existing styling.
          // And also hide all inner conten or UI elements.
          .content-element {
            // Optional:
            // max-height: 222px;

            // background-color: var(--pb-cms-color);
            // Note: This is not the pb-cms-color because of contrast to the buttons.
            background: repeating-linear-gradient(
              -45deg,
              #41a1dd 0,
              #41a1dd 20px,
              var(--pb-white) 20px,
              var(--pb-white) 40px
            ) !important;

            * {
              visibility: transparent;
              opacity: 0;
            }
          }
        }
      }
    `}</style>
  );
};

export default CmsContentFrameStyle;
