import CmsAddButton from "@/components/cms/cmsAddButton/cmsAddButton";
import CmsPageWarning from "@/components/cms/cmsPageWarning/cmsPageWarning";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementHtmlStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEHtml } from "@/types/content-elements";
import { convertPropertyPath } from "@/utils/util";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { get as objectPathGet } from "object-path";
import React, { useEffect, useRef, useState } from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import HtmlContentElementStyleGlobal from "./htmlContentElementStyleGlobal";
import HtmlContentElementStyleScoped from "./htmlContentElementStyleScoped";

const CmsHtmlCssJsModal = dynamic(
  () => import("@/components/cms/cmsHtmlCssJsModal/cmsHtmlCssJsModal")
);

export interface HtmlContentElementProps {
  content: CEHtml;
  position: number;
  ceSettings?: ContentElementHtmlStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const HTML_CE_NAME = "htmlCE";

export default React.memo(function HtmlContentElement(
  props: HtmlContentElementProps
) {
  const HTML_SCOPED_CLASS = useScopedClassName(
    HTML_CE_NAME,
    props.content,
    props.position
  );

  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const dispatch = useAppDispatch();

  const htmlDivRef = useRef<HTMLDivElement | null>(null);

  const [htmlEditorValue, setHtmlEditorValue] = useState(props.content.html);
  const [cssEditorValue, setCssEditorValue] = useState(props.content.css);
  const [jsEditorValue, setJsEditorValue] = useState(props.content.js);
  const [showHtmlCssJsEditorModal, setShowHtmlCssJsEditorModal] =
    useState(false);

  // runs on mount and everytime the provided html content changes.
  // runs after HTML was changed and confirmed throught the HTML editor.
  useEffect(() => {
    // clear any existing children nodes
    let localHtmlDivRef = htmlDivRef.current;
    if (localHtmlDivRef) {
      localHtmlDivRef.innerHTML = "";
    }

    const htmlString = props.content.html;
    const jsString = props.content.js;
    const cssString = props.content.css;

    let combinedHtmlString = htmlString;
    if (jsString) {
      combinedHtmlString = combinedHtmlString + `<script>${jsString}</script>`;
    }
    if (cssString) {
      combinedHtmlString = combinedHtmlString + `<style>${cssString}</style>`;
    }

    // replace all <%PB={page.name}%> occurences and inject the values from window.page
    combinedHtmlString = combinedHtmlString.replace(
      /<%PB={?([\w.]+)}?%>/g,
      (match, path) => {
        if (path) {
          try {
            const templateValue = objectPathGet(
              window,
              convertPropertyPath(`window.${path}`)
            );
            return templateValue;
          } catch (error) {
            console.error(error);
            console.error(`could not resolve path window.${path}`);
            if (editView) {
              return `<%PB=ERROR-{${path}}%>`;
            }
          }
        }
        return "";
      }
    );
    if (!editView) {
      combinedHtmlString = combinedHtmlString.replaceAll("<%PB={}%>", "");
    }

    let ceHtmlDoc = null;
    ceHtmlDoc = new DOMParser().parseFromString(
      combinedHtmlString,
      "text/html"
    );
    ceHtmlDoc.body.childNodes.forEach(function (node: Node) {
      if (node.nodeType === Node.TEXT_NODE && node.textContent) {
        htmlDivRef.current?.appendChild(node.cloneNode(true));
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = node as HTMLElement;
        if (elementNode.tagName === "SCRIPT") {
          const newScriptElement = document.createElement("script");

          for (const attribute of elementNode.attributes) {
            newScriptElement.setAttribute(attribute.name, attribute.value);
          }

          if (elementNode.textContent) {
            newScriptElement.textContent = `try {${elementNode.textContent}} catch (error) {console.error(error);}`;
          }

          try {
            htmlDivRef.current?.appendChild(newScriptElement);
          } catch (error) {
            console.error(error);
          }
        } else {
          htmlDivRef.current?.appendChild(elementNode.cloneNode(true));
        }
      }
    });

    return () => {
      if (localHtmlDivRef) {
        localHtmlDivRef.innerHTML = "";
      }
    };
  }, [props.content.html, props.content.css, props.content.js, editView]);

  return (
    <>
      <ContentElementWrapper
        name={HTML_CE_NAME}
        scopedClassName={HTML_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div className={clsx(HTML_CE_NAME, HTML_SCOPED_CLASS)}>
          {/* This div will contain the actual HTML provided by the content manager */}
          <div
            className={HTML_CE_NAME + "-" + props.position + "-html"}
            ref={htmlDivRef}
          ></div>
          {editView && (
            <>
              <CmsAddButton
                id={`ce-html-${props.position}-add-button`}
                height={60}
                title={
                  props?.content?.html
                    ? tCms("editSourceCode")
                    : tCms("addSourceCode")
                }
                text={
                  props?.content?.html
                    ? tCms("editSourceCode")
                    : tCms("addSourceCode")
                }
                onClick={() => {
                  setShowHtmlCssJsEditorModal(true);
                }}
                disablePlusIcon
              />
              <CmsHtmlCssJsModal
                htmlValue={htmlEditorValue}
                jsValue={jsEditorValue}
                cssValue={cssEditorValue}
                htmlTooltip={tCms("htmlContentElement-HtmlTooltip")}
                jsTooltip={tCms("htmlContentElement-JsTooltip")}
                cssTooltip={tCms("htmlContentElement-CssTooltip")}
                show={showHtmlCssJsEditorModal}
                setShow={setShowHtmlCssJsEditorModal}
                pageObjectModal
                onConfirm={(
                  htmlString: string,
                  jsString: string,
                  cssString: string
                ) => {
                  dispatch(
                    updateAttributeAction({
                      attributePath: `draftPage.content[${props.position}].html`,
                      value: htmlString,
                    })
                  );
                  dispatch(
                    updateAttributeAction({
                      attributePath: `draftPage.content[${props.position}].js`,
                      value: jsString,
                    })
                  );
                  dispatch(
                    updateAttributeAction({
                      attributePath: `draftPage.content[${props.position}].css`,
                      value: cssString,
                    })
                  );
                }}
                promptComponent={
                  <p className="pb-1 cms-modal-text">
                    {tCms("htmlContentElement-prompt")}
                  </p>
                }
                hintComponent={
                  <CmsPageWarning
                    type="warning"
                    style={{
                      padding: "10px",
                      marginTop: "2px",
                      marginBottom: "2px",
                    }}
                    icon="triangle-exclamation-light"
                  >
                    <p className="pt-1 cms-modal-text">
                      {tCms("htmlContentElement-hint")}
                    </p>
                  </CmsPageWarning>
                }
              />
            </>
          )}
        </div>
      </ContentElementWrapper>
      <HtmlContentElementStyleGlobal {...props} />
      <HtmlContentElementStyleScoped
        {...props}
        scopedSelector={HTML_SCOPED_CLASS}
      />
    </>
  );
});
