import { useSortable } from "@dnd-kit/sortable";
import clsx from "clsx";

interface CmsDraggableItemDndKitProps {
  id: string | number;
  index?: number;
  withExternalDragHandler?: boolean;
  children: React.ReactNode;
}

/**
 * CmsDraggableItemDndKit
 * https://docs.dndkit.com/
 * https://docs.dndkit.com/presets/sortable/usesortable
 * @param props.id
 * @param props.index
 * @param props.withExternalDragHandler
 * @returns
 */
const CmsDraggableItemDndKit = (props: CmsDraggableItemDndKitProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
  });

  const style = transform
    ? {
        // transform: CSS.Translate.toString(transform),
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // Place for isDragging only Styling:
        ...(isDragging &&
          {
            // backgroundColor: `var(--pb-cms-color)`,
            // opacity: 0.8,
            // border: `3px dotted var(--pb-cms-color)`,
            // boxShadow:
            //   "-1px 0 15px 0 rgba(34,33,81,0.01), 0px 15px 15px 0 rgba(34,33,81,0.25);",
          }),
        transition,
      }
    : undefined;

  return (
    <div
      className={clsx(
        "draggable-item",
        `${props.id}`,
        isDragging ? "is-dragging" : ""
      )}
      ref={setNodeRef}
      {...attributes}
      {...(!props.withExternalDragHandler && { ...listeners })}
      style={{
        ...style,
        cursor: "default",
      }}
    >
      {props.children}
    </div>
  );
};

export default CmsDraggableItemDndKit;